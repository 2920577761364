<template>
  <!-- not operational -->
  <div class="not-operational-updating-stats" v-show="updatingStats">
    <div class="paragraph updating-stats">
      <img
          src="../assets/stats.png"
          alt="Stats"
          class="updating-stats__image"
      />
      <div>The statistical data is being updated.</div>
      <div>Please come back in a few minutes.</div>
    </div>
  </div>

  <form @submit.prevent="sendForm">
    <div class="container">
      <div class="calculator-container u-margin-top-medium">
        <div class="u-text-align-center">
          <div class="heading-primary">Female Delusion Calculator</div>
          <div class="home-screen-container">
            <img
                class="home-screen-img"
                src="/home_screen.png"
                alt="Home Screen"
            />
            <!-- we're open swinging image -->
<!--            <a class="button-image shop-link" href="https://igotstandards.shop/">-->
<!--              <img class="shop-img" src="@/assets/were_open.png" width="600" height="523" alt="Shop">-->
<!--            </a>-->
          </div>
          <div class="heading-tertiary" id="calculator">
            What are the chances to find the man of my dreams?
          </div>
          <div class="paragraph">
            Live search using surveys conducted by
            <router-link class="button-text-accent" :to="{ name: 'Stats' }"
            >US Census Bureau and NCHS
            </router-link
            >
          </div>
        </div>
        <!-- Ezoic - top_of_page - top_of_page -->
        <div class="u-text-align-center" id="ezoic-pub-ad-placeholder-101"/>
        <!-- End Ezoic - top_of_page - top_of_page -->

        <div class="calculator-grid u-margin-top-medium">
          <!-- AGE -->
          <div class="heading-secondary u-text-align-center">Age</div>
          <Slider
              class="u-margin-top-medium"
              v-model="ageSlider.value"
              v-bind="ageSlider"
              @change="ageChanged"
          />

          <!-- MARITAL STATUS -->
          <label class="input-container u-margin-top-small">
            Exclude married
            <input name="race" type="checkbox" v-model="excludeMarried"/>
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="calculator-grid u-margin-top-small">
          <!-- RACE -->
          <div class="heading-secondary u-text-align-center">Race</div>
          <div>
            <label class="input-container">
              Any color or shade
              <input
                  name="race"
                  type="radio"
                  value="0"
                  v-model="race"
                  checked="checked"
              />
              <span class="radio"></span>
            </label>
            <label class="input-container">
              White
              <input name="race" type="radio" value="1" v-model="race"/>
              <span class="radio"></span>
            </label>
            <label class="input-container">
              Black
              <input name="race" type="radio" value="2" v-model="race"/>
              <span class="radio"></span>
            </label>
            <label class="input-container">
              Asian
              <input name="race" type="radio" value="3" v-model="race"/>
              <span class="radio"></span>
            </label>
          </div>
        </div>

        <div class="calculator-grid u-margin-top-small">
          <!-- HEIGHT -->
          <div class="heading-secondary u-text-align-center">Min. Height</div>
          <Slider
              class="u-margin-top-medium"
              v-model="heightSlider.value"
              v-bind="heightSlider"
          />

          <!-- WEIGHT -->
          <label class="input-container u-margin-top-small">
            Exclude obese
            <input name="race" type="checkbox" v-model="excludeObese"/>
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="calculator-grid u-margin-top-small">
          <!-- INCOME -->
          <div class="heading-secondary u-text-align-center">Min. Income</div>
          <Slider
              class="u-margin-top-medium"
              v-model="incomeSlider.value"
              v-bind="incomeSlider"
          />
        </div>

        <!-- Ezoic - before_button - mid_content -->
        <div class="u-text-align-center" id="ezoic-pub-ad-placeholder-102"/>
        <!-- End Ezoic - before_button - mid_content -->

        <!-- Ezoic - sidebar - mid_content -->
        <div id="ezoic-pub-ad-placeholder-116"/>
        <!-- End Ezoic - sidebar - mid_content -->

        <!-- Visit the store! -->
<!--        <div class="u-text-align-center u-margin-top-medium"-->
<!--             :style="{display: 'flex', flexDirection: 'column', alignItems: 'center'}">-->
<!--          <a class="button-image"-->
<!--             href="https://igotstandards.shop/">-->
<!--            <div class="tshirt-button-container">-->
<!--              <img class="visit-store-img" src="/igs_shop/swipe_tshirts.jpg" alt="Store">-->
<!--              <div class="paragraph-bold visit-store-text">SHOP NOW!</div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->

        <!-- Submit -->
        <button
            class="calculator-button button-accent u-margin-top-medium"
            type="submit"
            name="button"
        >
          Find out
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Slider from '@vueform/slider';
import conversions from '@/utils/conversions';
import ezoic from '@/utils/ezoic';

export default {
  name: 'Home',
  components: {
    Slider
  },

  data() {
    return {
      ageSlider: {
        value: [20, 40],
        min: 18,
        max: 85
      },
      prevAge: null,
      minAgeDelta: 5,

      excludeMarried: false,

      incomeSlider: {
        value: 80000,
        max: 500000,
        step: 5000,
        format: (value) => {
          if (value == 0) {
            return 'Any';
          }
          let mValue = Math.round(value / 1000);
          return `$ ${mValue}k`;
        }
      },

      heightSlider: {
        value: 182.88,
        min: 121.92,
        max: 213.36,
        step: 2.54,
        format: conversions.cmToImperial
      },

      race: 0,

      excludeObese: false
    };
  },

  created() {
    // get the previous values from vuex storage if possible
    let storedValues = this.$store.getters.calcFormValues;

    if (storedValues) {
      this.ageSlider.value = [storedValues.min_age, storedValues.max_age];
      this.excludeMarried = storedValues.exclude_married;
      this.incomeSlider.value = storedValues.min_income;
      this.heightSlider.value = storedValues.min_height;
      this.race = storedValues.race;
      this.excludeObese = storedValues.exclude_obese;
    }

    // store the current slider values
    this.prevAge = [this.ageSlider.value[0], this.ageSlider.value[1]];
  },

  mounted() {
    // update ezoic ads for this page
    ezoic.updateAds([101, 102]);
  },

  computed: {
    // set to true if you want to show the website is down because of stats update in progress
    updatingStats() {
      return false;
    }
  },

  methods: {
    // collect data from the ui and send that data to the Results page to process it
    sendForm() {
      let data = {
        minAge: this.ageSlider.value[0],
        maxAge: this.ageSlider.value[1],
        excludeMarried: this.excludeMarried,
        race: this.race,
        minHeight:
            this.heightSlider.value < 122.0 ? 0.0 : this.heightSlider.value,
        excludeObese: this.excludeObese,
        minIncome: this.incomeSlider.value
      };

      this.$router.push({ name: 'Results', query: data });
    },

    // make sure the min age delta is always respected
    ageChanged(newValue) {
      // find out which end of the range has changed
      let changedIdx = this.prevAge[0] != newValue[0] ? 0 : 1;

      // if the difference is less than the minimum delta, expand the current delta back
      if (newValue[1] - newValue[0] < this.minAgeDelta) {
        if (changedIdx == 0) {
          newValue[0] = newValue[1] - this.minAgeDelta;
          this.ageSlider.value[0] = newValue[0];
        } else if (changedIdx == 1) {
          newValue[1] = newValue[0] + this.minAgeDelta;
          this.ageSlider.value[1] = newValue[1];
        }
      }

      // update the prev age value
      this.prevAge = newValue;
    }
  }
};
</script>

<style lang="scss" src="@/themes/slider.scss"></style>
<style scoped lang="scss">
.calculator-container {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
}

.calculator-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 1.5rem;
  align-items: center;

  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background-color: $color-grey-1;
  border-radius: $border-radius-small;
}

.calculator-button {
  padding: 2rem 3rem;
  width: 100%;
}

.home-screen-container {
  position: relative;
}

// shop related classes
.shop-link {
  position: absolute;
  top: 6rem;
  right: 7rem;
  transform-origin: 50% .875rem;
  transform: rotate(30deg);
  animation: pendulum 1.5s ease-in-out infinite;
}

.shop-img {
  width: 15rem;
  height: 13.075rem;
}

.home-screen-img {
  width: 95%;
  margin: 2rem 0 6rem 0;

  @include respond(medium) {
    width: 70%;
  }
}

.not-operational-updating-stats {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  font-size: $default-font-size;
  background-color: rgba($color-black, 0.8);
}

.updating-stats {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 16rem;
    margin-bottom: 2rem;
  }
}
</style>
